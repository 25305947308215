<template>
  <div class="temp">
    <div class="d-flex align-center justify-center temp">
      <div>
        <h1>Access Denied</h1>
      <p>This application can only be used on the desktop</p>
      </div>
    </div>
    <img class="img" src="../../assets/img/Device not support0.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.temp {
  height: 100vh;
}
.img {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
}
.breakpoint {
  position: relative;
  background: burlywood;
}
</style>